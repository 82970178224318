import React from 'react'
import { fieldTypes, fonts } from '../const'
import MultipleValues from './MultipleValues'

export default ({ annotation }) => {
  const onChangeName = e => annotation.setName(e.target.value)
  const onChangeValue = e => annotation.setValue(e.target.value)
  const onChangeDefault = e => annotation.setDefault(e.target.value)
  const onChangeFont = e => annotation.setFont(e.target.value)
  const onChangeFontSize = e => annotation.setFontSize(e.target.value)
  const onChangeTextAlign = value => () => annotation.setTextAlign(value)
  const onChangeMultiline = e => annotation.setMultiline(e.target.checked)
  return (
    <div className="menu">
      <h3>Edition du champ</h3>
      Nom:
      <input className="inputText" type="text" onChange={onChangeName} value={annotation.name} />
      <br />
      {fieldTypes[annotation.type] === 'e_radio' && (
        <div>
          Value: <input className="inputTextLine" type="text" onChange={onChangeValue} value={annotation.value} />
          <br />
        </div>
      )}
      {fieldTypes[annotation.type] === 'e_choice' && <MultipleValues annotation={annotation} />}
      {fieldTypes[annotation.type] === 'e_text' && (
        <div>
          Défaut: <input className="inputTextLine" type="text" onChange={onChangeDefault} value={annotation.default} />
          <br />
        </div>
      )}
      {fieldTypes[annotation.type] === 'e_text' && (
        <div>
          {/* Obligatoire: <input type="checkbox" />{this.state.selectedAnnotation.isRequired}
                Lecture seule: <input type="checkbox" />{this.state.selectedAnnotation.readOnly}<br /> */}
          Multiline: <input type="checkbox" checked={annotation.multiline} onChange={onChangeMultiline} />
          <br />
          Style:
          {false && (
            <select onChange={onChangeFont} value={annotation.font}>
              {fonts.map(font => (
                <option key={font} value={font}>
                  {font}
                </option>
              ))}
            </select>
          )}
          <select onChange={onChangeFontSize} value={annotation.fontSize}>
            {[...Array(50)].map((value, index) => (
              <option key={index} value={`${index + 1}px`}>
                {index + 1}px
              </option>
            ))}
          </select>
          <button className="menuButton" onClick={onChangeTextAlign('left')}>
            G
          </button>
          <button className="menuButton" onClick={onChangeTextAlign('center')}>
            C
          </button>
          <button className="menuButton" onClick={onChangeTextAlign('right')}>
            D
          </button>
        </div>
      )}
        <div>
          <button className="menuButton" onClick={annotation.clone}>
            Duplicate
          </button>
          <br />
        </div>
    </div>
  )
}
