import './normalize.css'
import './App.css'

import React, { Component } from 'react'
import Loader from 'react-loader-spinner'
import AnnotationProperties from './components/AnnotationProperties'
import { availableFields } from './const'
import PdfManager from './pdf/PdfManager'
import Queue from './utils/Queue'
import debounce from './utils/debounce'

class App extends Component {

  state = {
    fields: [],
    mode: 1,
    inputSearch: '',
    selectedAnnotation: null,
    type: null
  }

  selectedAnnotation = null

  constructor() {
    super()
    this.viewer = React.createRef()
    this.viewerPreview = React.createRef()
    this.queue = new Queue()
  }

  componentDidMount() {
    window.parent.postMessage({ title: 'ready' }, '*')
    window.addEventListener('message', this.messageHandler)
  }

  initPdfViewer = async url => {
    try {
      await this.pdfManager.initWebViewer(this.viewer.current, url)

      this.pdfManager.on('convertedToForm', () => this.setState({ mode: 1 }))

      this.pdfManager.on('annotationsLoaded', () => {
        this.setFieldsMenu()
        if(!this.state.type){
          this.pdfManager.convertFormToAnnot()
        } else {
          this.pdfManager.convertFormToAnnot(true)
        }
      })

      this.pdfManager.on('convertedToAnnot', () => {
        this.setState({ mode: 0 })
        this.setFieldsMenu()
      })

      this.pdfManager.on('annotationChanged', (annotations, action) => {
        this.updatePreview()
        this.setFieldsMenu()
      })

      this.pdfManager.on('fieldChanged', () => {
        this.setFieldsMenu()
      })
      
      this.pdfManager.on('pageNumberUpdated', pageNumber => {
        if(this.state.preview && this.previewManager.docViewer.getCurrentPage() !== pageNumber){
          this.previewManager.docViewer.setCurrentPage(pageNumber)
          this.loadAnnotationsOnPreview()
        }
      })

      this.pdfManager.on('annotationUpdate', (annotation, action) => {
        if(!annotation){
          this.updatePreview()
        }
        this.setState({
          selectedAnnotation: annotation,
        })
      })

    } catch (e) {
      alert("Le document n'a pas pu être chargé correctement")
    }
  }

  messageHandler = async event => {
    try {
      if (!event.data || !event.data.title) {
        return
      }
      if(event.data.title === 'type'){
        this.setState({ type: event.data.type})
      }
      if (event.data.title === 'documentToLoad') {
        this.pdfManager = new PdfManager()
        await this.initPdfViewer(event.data.url)
      }
      if (event.data.title === 'exit') {
        await this.pdfManager.cleanup()
      }
    } catch (e) {
      alert("Le document n'a pas pu être chargé correctement")
    }
  }

  /****** PREMADE FIELDS ******/

  setFieldsMenu = () => {
    this.setState({ fields: [...this.pdfManager.annotManager.getAnnotationsList()] })
  }

  getUsedFields = () =>
    this.state.fields.map(field => {
      return (field.custom && field.custom.name) || field.getContents()
    })

  getAvailableFields = () => {
    if (this.state.inputSearch) {
      return availableFields.sort((a, b) => a.localeCompare(b)).filter(name =>name.includes(this.state.inputSearch))
    }
    return availableFields.sort((a, b) => a.localeCompare(b)).filter(name => this.isFieldExist(name))
  }

  getMatchingFields = regex => this.getUsedFields().filter(value => regex.test(value))

  isFieldExist = (name) =>this.getUsedFields().indexOf(name) === -1

  handleSearchChange = e => this.setState({ inputSearch: e.target.value })

  hasSignField = () => {
    if(!this.pdfManager) return
    const consumerFieldsLength = this.pdfManager.annotManager.getAnnotationsList().map(field => (field.custom && field.custom.name) || field.getContents()).filter(value => /^sign_consumer_1_/.test(value)).length > 0
    const promoterFieldsLength = this.pdfManager.annotManager.getAnnotationsList().map(field => (field.custom && field.custom.name) || field.getContents()).filter(value => /^sign_promoter_1_/.test(value)).length > 0
    return consumerFieldsLength && promoterFieldsLength
  }

  /****** ADD FIELD ******/

  addTextField = name => () =>
    this.pdfManager.addFormFieldAnnot(this.pdfManager.PDFNet.Field.Type.e_text, name)

  addCheckField = name => () =>
    this.pdfManager.addFormFieldAnnot(this.pdfManager.PDFNet.Field.Type.e_check, name)

  addRadioField = name => () =>
    this.pdfManager.addFormFieldAnnot(this.pdfManager.PDFNet.Field.Type.e_radio, name)

  addSignatureField = (name, label, alternative) => () =>
    this.pdfManager.addFormFieldAnnot(this.pdfManager.PDFNet.Field.Type.e_signature, name, label, true, alternative)

  addChoiceField = name => () => {
    return this.pdfManager.addFormFieldAnnot(this.pdfManager.PDFNet.Field.Type.e_choice, name)
  }

  addConsumerSignField = () => 
    this.addSignatureField(`sign_consumer_1_${new Date().getTime()}${this.getMatchingFields(/^sign_consumer_1_/).length + 1}`, 'SIGNATURE ACQUÉREUR' )()

  addCoConsumerSignField = () => 
    this.addSignatureField(`sign_consumer_2_${new Date().getTime()}${this.getMatchingFields(/^sign_consumer_2_/).length + 1}`, 'SIGNATURE CO-ACQUÉREUR')()

  addPromoterSignField = () => 
    this.addSignatureField(`sign_promoter_1_${new Date().getTime()}${this.getMatchingFields(/^sign_promoter_1_/).length + 1}`, 'SIGNATURE PROMOTEUR', true)()



  /****** PREVIEW ******/

  preview = async () => {
    this.setState({preview: true}, async () => {
      const blob = await this.pdfManager.getBlob()
      this.previewManager = new PdfManager()

      this.previewManager.on('documentLoaded', this.loadAnnotationsOnPreview)
      this.previewManager.on('documentLoaded', () => this.previewManager.docViewer.setCurrentPage(this.pdfManager.docViewer.getCurrentPage()))
           
      this.previewManager.on('pageNumberUpdated', pageNumber => {
        if(this.state.preview && this.pdfManager.docViewer.getCurrentPage() !== pageNumber){
          this.pdfManager.docViewer.setCurrentPage(pageNumber)
          this.loadAnnotationsOnPreview()
        }
      })
      await this.previewManager.initWebViewer(this.viewerPreview.current, blob, true)
    })
  }

  closePreview = async () => {
    if(this.previewManager){
      this.previewManager.cleanup()
    }
    this.setState({preview: false})
  }

  updatePreview = async () => {
    if(!this.state.preview){
      return
    }
    this.loadAnnotationsOnPreview()
  }


  loadAnnotationsOnPreview = debounce(() => this.queue.add(async () => {
    await this.previewManager.removeAllFields()
    const annotManager = this.pdfManager.docViewer.getAnnotationManager()
    const annotationsList = [...annotManager.getAnnotationsList()]
    await this.previewManager.convertAnnotToFormField(true, annotationsList)
  }), 350, true)


  /****** REPLACE ******/

  replacePdf = (e) => {
    this.closePreview()
    const file = e.target.files[0]
    const blob = new Blob([file], { type:'application/pdf' })
    this.pdfManager.replacePdf(blob)
  }


  /****** EXIT ******/

  cleanUp = () => {
    this.pdfManager.cleanup()
    this.setState({ leave: true })
  }

  close = () => window.parent.postMessage({ title: 'close' }, '*')

  render() {
    if (this.state.leave) {
      return null
    }
    this.hasSignField()
    return (
      <div className="App">
        <div className="popup"></div>
        <div className="container">
          <div className="webviewer" ref={this.viewer}></div>
          {this.state.preview && <div className="webviewer" ref={this.viewerPreview}></div>}

          <div className="fields">
            {this.state.mode !== 0 && (
              <div className="loaderContainer">
                <Loader type="ThreeDots" color="grey" height={30} width={30} />
              </div>
            )}
            {this.state.mode === 0 && this.state.type === 'addsign' && (
              <React.Fragment>
                <div className="menu">
                  <h3>Ajouter un champ de signature</h3>
                  <div className="addsign">
                    <button className="addsignButton" onClick={this.addConsumerSignField}>
                      Acquéreur
                    </button>
                    <button className="addsignButton" onClick={this.addCoConsumerSignField}>
                      Co-Acquéreur
                    </button>
                    <button className="addsignButton" onClick={this.addPromoterSignField}>
                      Promoteur
                    </button>
                  </div>
                </div>
                {this.state.mode === 0 && (
                <div className="menuAuto">
                    <button className="validSignButton" onClick={this.close}>
                      Annuler
                    </button>
                    {this.hasSignField() && 
                      <button className="validSignButton" onClick={() => this.pdfManager.convertAnnotToFormField(false, null, true)}>
                        Lancer la signature
                      </button>
                    }
                </div>
                  )}
              </React.Fragment>

            )}
            {this.state.mode === 0 && !this.state.type && (
              <React.Fragment>
                <div className="menu">
                  <h3>Ajouter champs</h3>
                  <div>
                    <button className="menuButton" onClick={this.addTextField()}>
                      + TextField
                    </button>
                    <button className="menuButton" onClick={this.addCheckField()}>
                      + Checkbox
                    </button>
                    <button className="menuButton" onClick={this.addRadioField()}>
                      + Bouton radio
                    </button>
                    <button className="menuButton" onClick={this.addChoiceField()}>
                      + Select
                    </button>
                    <button className="menuButton" onClick={this.addConsumerSignField}>
                      + Sign Acquéreur
                    </button>
                    <button className="menuButton" onClick={this.addCoConsumerSignField}>
                      + Sign Co-Acquéreur
                    </button>
                    <button className="menuButton" onClick={this.addPromoterSignField}>
                      + Sign Promoteur
                    </button>
                  </div>
                </div>
                <div className="menu">
                  <h3>Champs types</h3>
                  <input
                    className="inputText"
                    type="text"
                    value={this.state.inputSearch}
                    onChange={this.handleSearchChange}
                    placeholder={'Recherche'}
                  />
                  {this.state.mode === 0 &&
                    this.getAvailableFields()
                      .slice(0, 6)
                      .map(field => (
                        <button
                          key={field}
                          onClick={() =>
                            this.pdfManager.addFormFieldAnnot(this.pdfManager.PDFNet.Field.Type.e_text, field)
                          }
                          className="menuButton"
                        >
                          {field}
                        </button>
                      ))}
                </div>
                  {this.state.selectedAnnotation && <AnnotationProperties annotation={this.state.selectedAnnotation} pdfManager={this.pdfManager} />}
                <div className="menuSmall">
                <h3>Remplacer pdf</h3>
                  <input type="file" onChange={this.replacePdf} /><br/>
                <h3>Prévisualiser pdf</h3>
                  {!this.state.preview && 
                    <button className="menuButton" onClick={this.preview}>
                      Lancer
                    </button>
                  }
                  {this.state.preview && 
                    <button className="menuButton" onClick={this.closePreview}>
                      Fermer
                    </button>
                  }
                </div>
                {this.state.mode === 0 && (
                <div className="menuAuto">
                  {false && <button className="menuButton">Remplir avec des valeurs fictives</button>}
                  {false && <button className="menuButton">RAZ</button>}
                    <button className="menuButton" onClick={this.close}>
                      Annuler
                    </button>
                    <button className="menuButton" onClick={() => this.pdfManager.convertAnnotToFormField(false)}>
                      Sauvegarder
                    </button>
                </div>
                  )}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default App
