
export default class Queue {

  isOngoing = false
  queue = []

  add = fn => {
    this.queue.push(fn)
    this.launchQueue()
  }

  launchQueue = () => {
    if(!this.isOngoing){
      this.launchFirstFn()
    }
  }

  launchFirstFn = async () => {
    if(this.queue.length === 0){
      return
    }
    this.isOngoing = true
    await this.queue.shift()()
    this.isOngoing = false
    this.launchFirstFn()
  }

}