import { fieldTypes } from '../const'
import lorem from '../utils/lorem'

//@export to consts
const justification = {
  left: 0,
  center: 1,
  right: 2,
}

export default class FieldGenerator {
  e_button = []
  e_check = []
  e_radio = []
  e_text = []
  e_choice = []
  e_signature = []
  e_null = []

  constructor({pdfManager, annotations, highlight, fakeContent}) {
    this.pdfManager = pdfManager
    this.highlight = highlight
    this.annotations = this.getValidAnnotations(annotations)
    this.annotationsToRemove = this.getAnnotationsToRemove(annotations)
    this.fakeContent = fakeContent
  }

  init = async () => {
    this.currentDocument = this.pdfManager.docViewer.getDocument()
    this.pdfDoc = await this.currentDocument.getPDFDoc()
  }

  isValidAnnotation = annotation => !!this.getAnnotName(annotation) && annotation.custom

  getValidAnnotations = annotations => annotations.filter(annotation => this.isValidAnnotation(annotation))

  getAnnotationsToRemove = annotations => annotations.filter(annotation => !this.isValidAnnotation(annotation))

  sortAnnotationByType = () => this.annotations.forEach(annotation => this[fieldTypes[annotation.custom.type]].push(annotation))

  // Check that there is not two annotation with a same
  // name and a different type
  checkAnnotationSanity = () => fieldTypes.reduce((acc, fieldName) => {
    if(!this[fieldName]){
      return acc
    }
    const annotationNames = this[fieldName].map(annotation => this.getAnnotName(annotation))
    const uniqueNames = annotationNames.filter((name, index, self) => self.indexOf(name) === index)
    return acc.concat(uniqueNames)
  }, []).forEach((name, index, self) => {
    if(self.indexOf(name) !== index){
      throw new Error(`Des champs d'un type différent ne peuvent pas porter le même nom (${name})`)
    }
  })

  // If two annotations of the same type has the same name,
  // we add #1 for the second one and increment after (#2, #3, ...)
  renameAnnotations = () => fieldTypes.forEach(fieldName => {
    if(!this[fieldName] || fieldName === 'e_radio'){
      return
    }
    this[fieldName].map(annotation => this.getAnnotName(annotation)).forEach((name, index, self) => {
      if(self.indexOf(name) !== index){
        const testValue = new RegExp(`^${name}#[\\d]$`,'g');
        const numberOfValues = this[fieldName]
          .filter(annotation => testValue.test(this.getAnnotName(annotation))).length
        this[fieldName][index].setContents(`${name}#${new Date().getTime()}${numberOfValues + 1}`)
      }
    })
  })

  getAnnotName = annotation => annotation.custom && annotation.custom.name || annotation.getContents()

  generateFields = async (keepAnnot) => {
    this.sortAnnotationByType()
    this.checkAnnotationSanity()
    this.renameAnnotations()
    await this.generateTextFields()
    await this.generateCheckboxFields()
    await this.generateSignatureFields()
    await this.generateRadioFields()
    await this.generateChoiceFields()
    if(!keepAnnot){
      await this.removeUselessAnnotation()
    }
    await this.pdfDoc.refreshFieldAppearances()
  }

  removeUselessAnnotation = async () => {
    await Promise.all(
      this.annotationsToRemove.map(async annot => {
        await this.pdfManager.annotManager.deleteAnnotation(annot, false, true)
      })
    )
  }

  generateTextFields = async () => {
    await Promise.all(
      this.e_text.map(async annot =>
        this.createFieldFromAnnotation(annot, this.pdfManager).catch(e => {
          throw e
        })
      )
    )
  }

  generateChoiceFields = async () => {
    await Promise.all(
      this.e_choice.map(async annot =>
        this.createFieldFromAnnotation(annot, this.pdfManager).catch(e => {
          throw e
        })
      )
    )
  }

  generateCheckboxFields = async () =>
    Promise.all(
      this.e_check.map(async annot =>
        this.createFieldFromAnnotation(annot, this.pdfManager).catch(e => {
          throw e
        })
      )
    )

  generateSignatureFields = async () =>
    Promise.all(
      this.e_signature.map(async annot =>{
        const validName = /^sign_consumer_/.test(this.getAnnotName(annot)) || /^sign_promoter_/.test(this.getAnnotName(annot))
        if(!validName){
          return
        }
        return this.createFieldFromAnnotation(annot, this.pdfManager).catch(e => {
          throw e
        })
      })
    )

  generateRadioFields = async () => {
    const groupedRadio = this.e_radio.reduce((acc, annotation) => {
      const groupName = this.getAnnotName(annotation)
      if (!acc[groupName]) {
        acc[groupName] = []
      }
      acc[groupName].push(annotation)
      return acc
    }, {})
    await Promise.all(
      Object.keys(groupedRadio).map(group => this.createRadioFromAnnotation(group, groupedRadio[group]))
    )
  }

  //@reformat, clarify
  createField = async annotation => this.pdfDoc
      .fieldCreateFromStrings(
        this.getAnnotName(annotation),
        annotation.custom.type,
        annotation.custom.default
          || (this.fakeContent
              ? lorem()
              : '')
      , '')
      .catch(e => {
        throw e
      })

  createRectFromAnnotation = async annotation => {
    const annotRect = await annotation.getRect()
    const setTopLeft = this.currentDocument.getPDFCoordinates(
      annotation.getPageNumber() - 1,
      annotRect.x1,
      annotRect.y1
    )

    const setBottomRight = this.currentDocument.getPDFCoordinates(
      annotation.getPageNumber() - 1,
      annotRect.x2,
      annotRect.y2
    )

    return this.pdfManager.PDFNet.Rect.init(setTopLeft.x, setTopLeft.y, setBottomRight.x, setBottomRight.y)
  }

  

  createFieldAnnotation = async (annotation, field) => {
    let newAnnot
    if (annotation.custom.type === 4) {
      newAnnot = await this.pdfManager.PDFNet.ComboBoxWidget.create(
        this.pdfDoc,
        await this.createRectFromAnnotation(annotation).catch(e => {
          throw e
        }),
        await field.getName().catch(e => {
          throw e
        })
      ).catch(e => {
        throw e
      })
      // Add option values for the choice type
      if(annotation.custom && annotation.custom.values){
        annotation.custom.values.forEach(opt => newAnnot.addOption(opt))
      }
    } else {
      newAnnot = await this.createAnnot(newAnnot, annotation, field)
    }
    if(this.highlight){
      await newAnnot.setBackgroundColor(
        await this.pdfManager.PDFNet.ColorPt.init(255, 255, 196), 4
      )
    }
    const font = await this.addFont()
    newAnnot.setFontSize(parseInt(annotation.FontSize.match(/\d+/)[0]))
    newAnnot.setFont(font)

    if(annotation.custom.type === 1){
      const sdf = await field.getSDFObj().catch(e => {
        throw e
      })
      await newAnnot.setAppearance(sdf, this.pdfManager.PDFNet.Annot.State.e_normal, annotation.custom.value);
      await field.setValueAsBool(annotation.custom.value === annotation.custom.default)
      // await field.setValueAsBool(true)
      // const defualAppearance = await newAnnot.getAppearance()
      // // await newAnnot.setAppearance()
      // const name = await field.getName()
      // const defaultValue = await field.getValue()
      // console.log(field, newAnnot, defualAppearance, name)
    }
    if(annotation.custom.multiline){
      await field.setFlag(7, annotation.custom.multiline)
    }
    field.setJustification(justification[annotation.TextAlign])
    return newAnnot
  }

  createRadioFromAnnotation = async (group, annotations) => {
    const { pdfManager } = this
    const radioField = await this.pdfDoc.fieldCreateFromStrings(group, pdfManager.PDFNet.Field.Type.e_radio, '', '')

    for (let index = 0; index < annotations.length; index++) {
      const rect = await this.createRectFromAnnotation(annotations[index]).catch(e => {
        throw e
      })
      const annot = await pdfManager.PDFNet.WidgetAnnot.create(this.pdfDoc, rect, radioField).catch(e => {
        throw e
      })
      const sdf = await radioField.getSDFObj().catch(e => {
        throw e
      })
      await annot.setAppearance(sdf, 0, annotations[index].custom.value).catch(e => {
        throw e
      })
      const bgColor = await pdfManager.PDFNet.ColorPt.init(255, 255, 255, 1).catch(e => {
        throw e
      })
      const borderColor = await pdfManager.PDFNet.ColorPt.init(0, 0, 0, 1).catch(e => {
        throw e
      })
      annot.setBackgroundColor(bgColor, 3)
      annot.setBorderColor(borderColor, 3)
      const pageNumber = annotations[index].getPageNumber()
      const page = await this.pdfDoc.getPage(pageNumber).catch(e => {
        throw e
      })
      pdfManager.annotManager.deleteAnnotation(annotations[index], false, true)
      await page.annotPushBack(annot).catch(e => {
        throw e
      })
    }
  }

  createFieldFromAnnotation = async annotation => {
    const { pdfManager } = this
    let field
    if (annotation.custom) {
      field = await this.createField(annotation)
    }

    if (!field) {
      // exit early for other annotations
      pdfManager.annotManager.deleteAnnotation(annotation, false, true) // prevent duplicates when importing xfdf
      return
    }

    const newAnnot = await this.createFieldAnnotation(annotation, field)
    // Delete old notification
    await pdfManager.annotManager.deleteAnnotation(annotation, false, true)

    // draw the annotation the viewer
    const pageNumber = annotation.getPageNumber()
    const page = await this.pdfDoc.getPage(pageNumber)
    await page.annotPushBack(newAnnot)
  }

  // Add font to the document on first call then alway return same pointer
  addFont = async () => {
    if (!this.font) {
      this.font = await this.pdfManager.PDFNet.Font.createAndEmbed(
        this.pdfDoc,
        this.pdfManager.PDFNet.Font.StandardType1Font.e_helvetica
      ).catch(e => {
        throw e
      })
    }
    return this.font
  }

  createAnnot = async (newAnnot, annotation, field) => {
    newAnnot = await this.pdfManager.PDFNet.WidgetAnnot
      .create(this.pdfDoc, await this.createRectFromAnnotation(annotation), field)
      .catch(e => {
        throw e
      })
    return newAnnot
  }

   createRadioAnno = async (newAnnot, annotation, field) => {
    newAnnot = await this.pdfManager.PDFNet.ComboBoxWidget
      .create(this.pdfDoc, 
        await this.createRectFromAnnotation(annotation)
          .catch(e => {
            throw e
          }),
        await field.getName()
          .catch(e => {
            throw e
          })
      ).catch(e => {
        throw e
      })
    // Add option values for the choice type
    annotation.custom.values.forEach(opt => newAnnot.addOption(opt))
    return newAnnot
  }
}
