import { fieldTypes } from '../const'

const createAnnotation = async (type, name, PdfManager, FontSize, label, big, alternative) => {
  const dropPoint = {}
  const { docViewer } = PdfManager
  const annotManager = docViewer.getAnnotationManager()
  const doc = docViewer.getDocument()
  const displayMode = docViewer.getDisplayModeManager().getDisplayMode()
  const page = displayMode.getSelectedPages(dropPoint, dropPoint)
  if (!!dropPoint.x && page.first == null) {
    return // don't add field to an invalid page location
  }
  const pageIdx = page.first !== null ? page.first : docViewer.getCurrentPage() - 1
  const pageInfo = doc.getPageInfo(pageIdx)
  const pagePoint = displayMode.windowToPage(dropPoint, pageIdx)

  const textAnnot = new PdfManager.Annotations.FreeTextAnnotation()
  textAnnot.PageNumber = pageIdx + 1
  const rotation = docViewer.getCompleteRotation(pageIdx + 1) * 90
  textAnnot.Rotation = rotation
  textAnnot.Width = big ? 120 : 100
  textAnnot.Height = big ? 42 : 20
  if (fieldTypes[type] === 'e_check' || fieldTypes[type] === 'e_radio') {
    textAnnot.Width = 20
    textAnnot.Height = 20
  }
  textAnnot.X = 5
  textAnnot.Y = (pagePoint.y || pageInfo.height / 2) - textAnnot.Height / 2

  textAnnot.setPadding(new PdfManager.Annotations.Rect(0, 0, 0, 0))
  textAnnot.custom = {
    type,
    name
  }

  // set the type of annot
  textAnnot.setContents(label || name)
  textAnnot.FontSize = big ? FontSize || '10px' : FontSize || '15px' 
  textAnnot.FillColor = alternative ? new PdfManager.Annotations.Color(255, 246, 224) : new PdfManager.Annotations.Color(227, 238, 251)
  textAnnot.TextColor = alternative ? new PdfManager.Annotations.Color(199, 154, 47) : new PdfManager.Annotations.Color(58, 116, 183)
  textAnnot.StrokeThickness = 2
  textAnnot.StrokeColor = alternative ? new PdfManager.Annotations.Color(199, 154, 47) : new PdfManager.Annotations.Color(74, 144, 226)
  textAnnot.TextAlign = big ? 'center' : 'left'
  textAnnot.Author = annotManager.getCurrentUser()

  annotManager.deselectAllAnnotations()
  annotManager.addAnnotation(textAnnot, true)
  annotManager.redrawAnnotation(textAnnot)
  await PdfManager.annotManager.selectAnnotation(textAnnot)
}

export default createAnnotation
