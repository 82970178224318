import NanoEvents from 'nanoevents'
import FieldGenerator from './FieldGenerator'
import HWAnnotation from './HWAnnotation'
import createAnnotation from './createAnnotation'

export default class PdfManager extends NanoEvents {
  tempAnnotation = []

  initWebViewer = async (reference, initialDoc, noListener) => {
    try {
      this.instance = await window.WebViewer(
        {
          path: '/lib',
          disabledElements: [
            'linkButton',
            'leftPanelButton',
            'viewControlsButton',
            'panToolButton',
            'selectToolButton',
            'freeHandToolGroupButton',
            'textToolGroupButton',
            'shapeToolGroupButton',
            'eraserToolButton',
            'signatureToolButton',
            'freeTextToolButton',
            'stickyToolButton',
            'miscToolGroupButton',
            'toolsButton',
            'searchButton',
            'menuButton',
          ],
          fullAPI: true,
          l:'HAPPYWAIT(happywait.com):OEM:HappyWait::B+:AMS(20220322):3FB52AE20467A80A7360B13AC9A2737860612F8D8116C5BAE5124B8295DF5CE25611BEF5C7'
        },
        reference
      )
      this.instance.setTheme({
        secondary: '#000000'
      })
      await this.loadDoc(initialDoc, noListener)
    } catch (e) {
      alert("Une erreur est survenue a l'initialisation")
    }
  }

  loadDoc = async (doc, noListener) => {
    await this.instance.loadDocument(doc)

    const { PDFNet, docViewer, Annotations, annotManager } = this.instance
    await this.instance.PDFNet.initialize()
    this.instance.setHeaderItems(header => {
      header.push({
      type: 'zoomOverlayButton',
      toolName: 'zoomOverlayButton',
      hidden: []
    
      })
    })
    // We dont prerender other pages so we keep memory usage as low as possible
    this.instance.CoreControls.SetPreRenderLevel(10)
    this.docViewer = docViewer
    this.annotManager = annotManager
    this.Annotations = Annotations
    this.PDFNet = PDFNet
    this.addListeners(noListener)
  }

  convertAnnotToFormField = async (noSave, annotations, keepAnnot) => {
    try {
      this.emit('convertedToForm')
      const pdfDoc = await this.getPDFDoc()
      const annotManager = this.docViewer.getAnnotationManager()
      const annotationsList = [...annotManager.getAnnotationsList()]
      const fieldGenerator = new FieldGenerator({
        pdfManager: this,
        annotations: annotations || annotationsList,
        highlight: noSave,
        fakeContent: !!annotations
      })
      await fieldGenerator.init()
      await fieldGenerator.generateFields(keepAnnot)
      // import newly created form fields
      const fdfDoc = await pdfDoc.fdfExtract(this.PDFNet.PDFDoc.ExtractFlag.e_both)
      const xfdf = await fdfDoc.saveAsXFDFAsString()
      await annotManager.importAnnotations(xfdf)

      this.refreshDocViewer()

      const doc = this.docViewer.getDocument()
      const xfdfString = await annotManager.exportAnnotations()
      const data = await doc.getFileData({
        xfdfString,
      })
      if(!noSave){
        this.savePDF(data)
      }
    } catch (e) {
      console.log(e)
      alert('Could not convert annot to form field ' + e)
    }
  }


  addListeners= (noListener) => {
    this.docViewer.on('annotationsLoaded', async () => {
      this.emit('annotationsLoaded')
    })
    this.docViewer.on('pageNumberUpdated', (pageNumber) => this.emit('pageNumberUpdated', pageNumber))
    this.docViewer.on('documentLoaded', async () => {
      await this.importTempAnnotations()
      this.emit('documentLoaded')
    })
    this.annotManager.off()
    this.annotManager.on('fieldChanged', () => {
      this.emit('fieldChanged')
    })
    if (!noListener) {
      this.annotManager.on('annotationChanged', (annotations, action) => {
        if (action === 'delete') {
          this.emit('annotationUpdate', null)
        }
        this.emit('annotationChanged', annotations, action)
      })
      this.annotManager.on('annotationLoaded', () => {
        this.emit('annotationLoaded')
      })
      this.annotManager.on('annotationSelected', async annotations => {
        if (annotations && annotations.length === 1 && annotations[0].custom) {
          if (annotations[0].custom.type === 3) {
            this.defaultFontSize = annotations[0].FontSize
          }
          this.emit('annotationUpdate', new HWAnnotation(annotations[0], this))
        }
        else {
          this.emit('annotationUpdate', null)
        }
        this.emit('annotationSelected', annotations)
      })
    }
  }

  importTempAnnotations = async () => {
    if (this.tempAnnotation.length > 0) {
      const currentDoc = await this.docViewer.getDocument()
      const pdfDoc = await currentDoc.getPDFDoc()
      const pageCount = await pdfDoc.getPageCount()
      this.tempAnnotation.forEach(async (annot) => {
        if (annot.PageNumber <= pageCount) {
          await this.annotManager.addAnnotation(annot, true)
          await this.annotManager.drawAnnotations(annot.PageNumber, null, true)
        }
      })
      this.tempAnnotation = []
    }
  }

  savePDF = (data) => {
    const arr = new Uint8Array(data)
    const blob = new Blob([arr], { type: 'application/pdf' })
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = function () {
      const base64data = reader.result
      window.parent.postMessage({ title: 'save', file: base64data }, '*')
    }
  }

  getBlob = async () => {
    const doc = this.docViewer.getDocument()
    return await doc.getFileData()
  }

  addAndDrawAnnottation = async annot => {
    await this.annotManager.addAnnotation(annot, true)
    await this.annotManager.drawAnnotations(annot.PageNumber, null, true)
  }

  // @Extract
  getTypeFromField = field => {
    if(field.type === 'Tx'){
      return 3
    }
    if(field.type === 'Ch'){
      return 4
    }
    if(field.type === 'Sig'){
      return 5
    }
    if(field.type === 'Btn'){
      if(field.widgets.length > 1){
        return 2
      }
      return 1
    }
  }

  //@explode
  convertFormToAnnot = async (hideFields) => {
    try {
      const fields = await this.getAllFields()

      // Loop over the fiels to create an annot
      await this.convertFieldToAnnotation(fields, hideFields)
      this.cleanFields()
      this.emit('convertedToAnnot')
    } catch (e) {
      console.log(e)
      alert('Could not convert annot to form field')
    }
  }

  removeAllFields = async () => {
    const fields = await this.getAllFields()
    for (let index = 0; index < fields.length; index++) {
      const field = fields[index]
      for (let indexWidget = 0; indexWidget < field.widgets.length; indexWidget++) {
        this.annotManager.deleteAnnotation(field.widgets[indexWidget], false, true)
      }
    }
    await this.cleanFields()
  }

  cleanFields = async () => {
    const pdfDoc = await this.getPDFDoc()
    const fdfDoc = await new this.PDFNet.FDFDoc.create()
    await pdfDoc.fdfUpdate(fdfDoc)
    this.refreshDocViewer()
  }

  refreshDocViewer = () => {
    this.docViewer.refreshAll()
    this.docViewer.updateView()
    // this.docViewer.getDocument().refreshTextData()
  }

  getPDFDoc = async () => {
    const currentDocument = this.docViewer.getDocument()
    return await currentDocument.getPDFDoc()
  }

  getAllFields = async () => {
    const fields = []
    const fieldManager = this.annotManager.getFieldManager()
    fieldManager.forEachField(field => fields.push(field))
    return fields
  }

  replacePdf = async (pdf) => {
      const annotManager = this.docViewer.getAnnotationManager()
      this.tempAnnotation = [...annotManager.getAnnotationsList()]
      await this.loadDoc(pdf)
  }

  addFormFieldAnnot = async (type, name, label, big, alternative) => {
    createAnnotation(type, name, this, this.defaultFontSize, label, big, alternative)
    this.emit('annotationChanged')
  }

  cleanup = () => {
    this.docViewer.off()
    this.annotManager.off()
    this.instance.dispose()
    this.docViewer.dispose()
  }


  convertFieldToAnnotation= async (fields, hideFields) => {
    //@extract to util
    const aligns = {
      'Left-justified': 'left',
      'Right-justified': 'right',
      Centered: 'center',
    }
    if(hideFields){
      return
    }
    for (let index1 = 0; index1 < fields.length; index1++) {
      const field = fields[index1]
      for (let index = 0; index < field.widgets.length; index++) {
        //@extract to utility function 'createAnnotFromField'
        const widget = field.widgets[index]
        const textAnnot = new this.Annotations.FreeTextAnnotation()
        textAnnot.X = widget.X
        textAnnot.Y = widget.Y
        textAnnot.Width = widget.Width
        textAnnot.Height = widget.Height
        textAnnot.FontSize = `${widget.font.size}px`
        textAnnot.FillColor = new this.Annotations.Color(0, 0, 0, 0.1)
        textAnnot.TextColor = new this.Annotations.Color(0, 0, 0)
        textAnnot.StrokeThickness = 0
        textAnnot.StrokeColor = new this.Annotations.Color(0, 0, 0)
        textAnnot.TextAlign = aligns[widget.quadding]
        textAnnot.setPageNumber(widget.PageNumber)
        textAnnot.setPadding(new this.Annotations.Rect(0, 0, 0, 0))
        textAnnot.custom = {
          type: this.getTypeFromField(field),
          readOnly: false,
          isRequired: false,
          default: widget.value,
          multiline: field.flags.get('Multiline')
        }
        // Set appearance (combo)
        const appearances = Object.keys(widget.appearances).filter(key => key !== 'Off')
        if (appearances.length === 1) {
          textAnnot.custom.value = appearances[0]
        }
        // Set select values (select)
        if (widget.options && widget.options.length > 0) {
          textAnnot.custom.values = widget.options.map(option => option.value)
          textAnnot.custom.values.push('')
        }
        textAnnot.Author = await this.annotManager.getCurrentUser()
        await textAnnot.setContents(field.name)
        this.addAndDrawAnnottation(textAnnot)
        this.annotManager.deleteAnnotation(widget, false, true)
      }
    }
  }
  cloneHwAnnotation = async (hwAnnotation) => {
    const textAnnot = new this.Annotations.FreeTextAnnotation()
    textAnnot.X = hwAnnotation.annotation.X
    textAnnot.Y = hwAnnotation.annotation.Y
    textAnnot.Width = hwAnnotation.annotation.Width
    textAnnot.Height = hwAnnotation.annotation.Height
    textAnnot.FontSize = hwAnnotation.annotation.FontSize
    textAnnot.FillColor = hwAnnotation.annotation.FillColor
    textAnnot.TextColor = hwAnnotation.annotation.TextColor
    textAnnot.StrokeThickness = hwAnnotation.annotation.StrokeThickness
    textAnnot.StrokeColor = hwAnnotation.annotation.StrokeColor
    textAnnot.TextAlign = hwAnnotation.annotation.TextAlign
    textAnnot.setPageNumber(hwAnnotation.annotation.PageNumber)
    textAnnot.setPadding(new this.Annotations.Rect(0, 0, 0, 0))
    textAnnot.custom = {...hwAnnotation.annotation.custom}
    textAnnot.Author = await this.annotManager.getCurrentUser()
    await textAnnot.setContents(hwAnnotation.annotation.getContents() || '')
    this.addAndDrawAnnottation(textAnnot)
  }
}
