
const batchTypes = [
  'terrain',
  'surfacecommerciale',
  'rangement',
  'parkingcouvert',
  'parking',
  'mobilier',
  'logement',
  'localvelo',
  'localcommercial',
  'garageexterieur',
  'garage',
  'cellier',
  'cave',
  'casierski',
  'carport',
  'bureau',
  'box',
  'annexecommercialisable',
  'annexe',
]

const batchsFields = () => {
  const results = []
  batchTypes.forEach(type => {
    results.push(`surface_${type}_` )
    results.push(`nom_lot_${type}_` )
    results.push(`prix_${type}_` )
    results.push(`prix_ht_${type}_` )
    results.push(`prix_tva_${type}_` )
  })
  return results
}

export const availableFields = [
  'surface',
  'surface_terrasse',
  'surface_jardin',
  'surface_plancher',
  'nb_piece',
  'etage',
  'stationnement',
  'cave_local',
  'livraison',
  'prix',
  'prix_ht',
  'prix_tva',
  'prix_unite',
  'prix_unite_ht',
  'prix_unite_tva',
  'prix_stationnement',
  'prix_stationnement_ht',
  'prix_stationnement_tva',
  'prix_cave',
  'prix_cave_ht',
  'prix_cave_tva',
  'infos',
  'nom_lot',
  'operation_lot',
  'structure_juridique',
  'montant_remise',
  'montant_remise_unite',
  'montant_pret',
  'montant_pret_unite',
  'taux_interet',
  'taux_interet_unite',
  'duree_pret',
  'duree_pret_unite',
  'banque_pret',
  'depot_garantie',
  'depot_garantie_unite',
  'numero_cheque',
  'banque',
  'date_reglement',
  'mode_paiement_depot_garantie',
  'depot_garantie_virement',
  'depot_garantie_virement_unite',
  'banque_virement',
  'depot_garantie_virement_lettre',
  'depot_garantie_virement_lettre_unite',
  'date_reglement_virement',
  'depot_garantie_cheque',
  'depot_garantie_cheque_unite',
  'banque_cheque',
  'depot_garantie_cheque_lettre',
  'depot_garantie_cheque_lettre_unite',
  'date_reglement_cheque',
  'notaire_acquereur',
  'notaire_acquereur_ville',
  'ville_promoteur',
  'ville_projet',
  'date_jour',
  'prix_lettre',
  'prix_lettre_ht',
  'prix_lettre_tva',
  'prix_lettre_unite',
  'prix_lettre_unite_ht',
  'prix_lettre_unite_tva',
  'montant_pret_lettre',
  'montant_pret_lettre_unite',
  'montant_remise_lettre',
  'montant_remise_lettre_unite',
  'depot_garantie_lettre',
  'depot_garantie_lettre_unite',
  'prix_stationnement_lettre',
  'prix_stationnement_lettre_ht',
  'prix_stationnement_lettre_tva',
  'prix_stationnement_lettre_unite',
  'prix_stationnement_lettre_unite_ht',
  'prix_stationnement_lettre_unite_tva',
  'nom_complet_acq',
  'prix_total',
  'prix_total_unite',
  'prix_total_ht',
  'prix_total_unite_ht',
  'prix_total_tva',
  'prix_total_unite_tva',
  'prix_total_lettre',
  'prix_total_lettre_ht',
  'prix_total_lettre_tva',
  'prix_total_lettre_unite',
  'prix_total_lettre_unite_ht',
  'prix_total_lettre_unite_tva',
  'surface_',
  'surface_terrasse_',
  'surface_jardin_',
  'surface_plancher_',
  'nb_piece_',
  'etage_',
  'stationnement_',
  'cave_local_',
  'livraison_',
  'prix_',
  'prix_ht_',
  'prix_tva_',
  'prix_unite_',
  'prix_unite_ht_',
  'prix_unite_tva_',
  'prix_stationnement_',
  'prix_stationnement_ht_',
  'prix_stationnement_tva_',
  'prix_cave_',
  'prix_cave_ht_',
  'prix_cave_tva_',
  'infos_',
  'nom_lot_',
  'operation_lot_',
  'montant_remise_',
  'montant_remise_unite_',
  'montant_pret_',
  'montant_pret_unite_',
  'taux_interet_',
  'taux_interet_unite_',
  'duree_pret_',
  'duree_pret_unite_',
  'banque_pret_',
  'depot_garantie_',
  'depot_garantie_unite_',
  'numero_cheque_',
  'banque_',
  'date_reglement_',
  'mode_paiement_depot_garantie_',
  'depot_garantie_virement_',
  'depot_garantie_virement_unite_',
  'banque_virement_',
  'depot_garantie_virement_lettre_',
  'depot_garantie_virement_lettre_unite_',
  'date_reglement_virement_',
  'depot_garantie_cheque_',
  'depot_garantie_cheque_unite_',
  'banque_cheque_',
  'depot_garantie_cheque_lettre_',
  'depot_garantie_cheque_lettre_unite_',
  'date_reglement_cheque_',
  'notaire_acquereur_',
  'notaire_acquereur_ville_',
  'ville_promoteur_',
  'ville_projet_',
  'date_jour_',
  'prix_lettre_',
  'prix_lettre_ht_',
  'prix_lettre_tva_',
  'prix_lettre_unite_',
  'prix_lettre_unite_ht_',
  'prix_lettre_unite_tva_',
  'montant_pret_lettre_',
  'montant_pret_lettre_unite_',
  'montant_remise_lettre_',
  'montant_remise_lettre_unite_',
  'depot_garantie_lettre_',
  'depot_garantie_lettre_unite_',
  'prix_stationnement_lettre_',
  'prix_stationnement_lettre_ht_',
  'prix_stationnement_lettre_tva_',
  'prix_stationnement_lettre_unite_',
  'prix_stationnement_lettre_unite_ht_',
  'prix_stationnement_lettre_unite_tva_',
  'nom_complet_acq_',
  'prix_total_',
  'prix_total_unite_',
  'prix_total_ht_',
  'prix_total_unite_ht_',
  'prix_total_tva_',
  'prix_total_unite_tva_',
  'prix_total_lettre_',
  'prix_total_lettre_ht_',
  'prix_total_lettre_tva_',
  'prix_total_lettre_unite_',
  'prix_total_lettre_unite_ht_',
  'prix_total_lettre_unite_tva_',
  'tauxtva_',
  'prix_total_lot',
  'prix_total_lot_unite',
  'prix_total_lot_ht',
  'prix_total_lot_unite_ht',
  'prix_total_lot_tva',
  'prix_total_lot_unite_tva',
  'prix_total_lot_lettre',
  'prix_total_lot_lettre_ht',
  'prix_total_lot_lettre_tva',
  'prix_total_lot_lettre_unite',
  'prix_total_lot_lettre_unite_ht',
  'prix_total_lot_lettre_unite_tva',
  'prix_total_lot_annexe_hors_stationnement',
  'prix_total_lot_annexe_hors_stationnement_unite',
  'prix_total_lot_annexe_hors_stationnement_ht',
  'prix_total_lot_annexe_hors_stationnement_unite_ht',
  'prix_total_lot_annexe_hors_stationnement_tva',
  'prix_total_lot_annexe_hors_stationnement_unite_tva',
  'prix_total_lot_annexe_hors_stationnement_lettre',
  'prix_total_lot_annexe_hors_stationnement_lettre_ht',
  'prix_total_lot_annexe_hors_stationnement_lettre_tva',
  'prix_total_lot_annexe_hors_stationnement_lettre_unite',
  'prix_total_lot_annexe_hors_stationnement_lettre_unite_ht',
  'prix_total_lot_annexe_hors_stationnement_lettre_unite_tva',
  'nom_lot_annexe_hors_stationnement',
  'prix_total_lot_annexe_stationnement',
  'prix_total_lot_annexe_stationnement_unite',
  'prix_total_lot_annexe_stationnement_ht',
  'prix_total_lot_annexe_stationnement_unite_ht',
  'prix_total_lot_annexe_stationnement_tva',
  'prix_total_lot_annexe_stationnement_unite_tva',
  'prix_total_lot_annexe_stationnement_lettre',
  'prix_total_lot_annexe_stationnement_lettre_ht',
  'prix_total_lot_annexe_stationnement_lettre_tva',
  'prix_total_lot_annexe_stationnement_lettre_unite',
  'prix_total_lot_annexe_stationnement_lettre_unite_ht',
  'prix_total_lot_annexe_stationnement_lettre_unite_tva',
  'nom_lot_annexe_stationnement',
  'nom_',
  'nom_societe_',
  'prenom_',
  'adresse_',
  'adresse_societe_',
  'forme_juridique_',
  'nom_complet_societe_',
  'telephone_societe_',
  'email_societe_',
  'cp_',
  'ville_',
  'nationality_',
  'mobile_',
  'profession_',
  'date_naissance_',
  'pays_',
  'civilite_',
  'type_societe_',
  'situation_familiale_',
  'date_mariage_',
  'lieu_mariage_',
  'date_pacs_',
  'lieu_pacs_',
  'contrat_mariage_',
  'notaire_mariage_',
  'notaire_ville_mariage_',
  'ville_naissance_',
  'adresse_suite_',
  'email_',
  'nom_complet_',
  'adresse_complete_',
  'profession_',
  'si_marie_',
  'si_union_libre_',
  'si_pacse_',
  'si_celibataire_',
  'si_divorce_',
  'si_veuf_',
  'representant_societe_',
  'capital_societe_',
  'siren_societe_',
  'rcs_ville_societe_',
  'rcs_societe_',
].concat(batchsFields())

export const fonts = [
    'Times-Roman',
    'Times-Bold',
    'Time-Italic',
    'Time-BoldItalic',
    'Courier',
    'Courier-Bold',
    'Courier-Oblique',
    'Helvetica',
    'Helvetica-Bold',
    'Helvetica-Oblique',
    'Helvetica-BoldOblique',
  ]

export const fieldTypes = [
    'e_button',
    'e_check',
    'e_radio',
    'e_text',
    'e_choice',
    'e_signature',
    'e_null'
]
