import React from 'react'

export default ({ annotation }) => {

    const updateValue = index => e => {
      const newValues = [...annotation.values]
      newValues[index] = e.target.value
      if(newValues[annotation.values.length -1] !== ""){
        newValues.push("")
      }
      annotation.setValues(newValues)
    }

    const removeValue = index => () => {
      const newValues = [...annotation.values]
      newValues.splice(index, 1)
      annotation.setValues(newValues)
    }

    return (
      <div>
        Values: 
        {annotation.values.map((value, index) => (
        <div key={`select-values-${index}`}>
          <input
            className="inputTextLine"
            type="text"
            onChange={updateValue(index)}
            value={value}
          />
          {index < annotation.values.length -1 && <input type="button" value="-" onClick={removeValue(index)}/>}
          <br />
        </div>
        ))}
      </div>
    )
}