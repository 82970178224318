export default class HWAnnotation {
  value = null

  default = null

  font = null

  fontSize = null

  textAlign = null

  multiline = null

  constructor(annotation, pdfManager) {
    this.annotation = annotation
    this.annotation.custom = annotation.custom || {}
    this.values = annotation.custom.values
    this.type = annotation.custom.type
    this.default = annotation.custom.default
    this.font = this.annotation.Font
    this.fontSize = this.annotation.FontSize
    this.textAlign = this.annotation.TextAlign
    this.pdfManager = pdfManager
    this.name = annotation.getContents() || ''
    this.value = annotation.custom.value || ''
    this.values = annotation.custom.values || ['']
    this.multiline = annotation.custom.multiline || false
  }

  setName = value => {
    this.name = value
    this.annotation.setContents(value)
    this.onUpdate()
  }

  setValue = value => {
    this.value = value
    this.annotation.custom.value = value
    this.onUpdate()
  }

  setValues = values => {
    this.values = values
    this.annotation.custom.values = values
    this.onUpdate()
  }

  setDefault = value => {
    this.default = value
    this.annotation.custom.default = value
    this.onUpdate()
  }

  setFont = value => {
    this.font = value
    this.annotation.Font = value
    this.onUpdate()
  }

  setFontSize = value => {
    this.fontSize = value
    this.annotation.FontSize = value
    this.onUpdate()
  }

  setTextAlign = value => {
    this.textAlign = value
    this.annotation.TextAlign = value
    this.onUpdate()
  }

  setMultiline = value => {
    this.multiline = value
    this.annotation.custom.multiline = value
    this.onUpdate()
  }

  onUpdate = () => {
    this.pdfManager.annotManager.redrawAnnotation(this.annotation)
    this.pdfManager.emit('annotationUpdate', { ...this })
  }

  clone = () => {
    console.log(this.annotation)
    this.pdfManager.cloneHwAnnotation(this)
  }
}
