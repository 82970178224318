import './index.css'

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

try {
  ReactDOM.render(<App />, document.getElementById('root'))
  serviceWorker.unregister()
} catch (e) {
  alert('Une erreur est survenue')
}
